import "./style.css";
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import * as dat from "dat.gui";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { _numWithUnitExp } from "gsap/gsap-core";

//GSAP------------------------------------------
gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);

(function () {
	("use strict");
	//ローダー部分=====================
	//作品のimg.videoに付けてるworksクラスを全て取得
	const nodelist = document.querySelectorAll(".works");
	//取得したworksの個数を確認
	const nodelistlength = nodelist.length;
	let loadcount = 0;
	let loadrate = 0;
	let svgrate = 0;
	let svgposition = 2700;
	const per = document.querySelector(".per");

	//取得したworksノードリストをforeachで一個ずつ取り出し
	nodelist.forEach((works) => {
		//もしimgタグならば
		if (works.nodeName === "IMG") {
			//イメージオブジェクト作ってworksからsrcアドレスをイメージオブジェクトのsrcにセット
			const img = new Image();
			img.src = works.currentSrc;
			//loadイベントのリスナーをセット
			img.addEventListener("load", () => {
				//発火したら読み込み済みカウントをプラス
				loadcount = loadcount + 1;
				//全体個数と読み込み済みカウントで済み割合を算出
				loadrate = Math.floor((loadcount / nodelistlength) * 100);
				//カウント済み割合をsvg用にも転用
				svgrate = loadcount / nodelistlength;
				//割合から現在のstroke-dashoffsetの数値を算出。
				//2700がスタート位置で、0になったら100％表示
				svgposition = 2700 - svgposition * svgrate;
				//useタグのid  stroke-dashoffsetを操作すれば手書き風に表現できる。
				gsap.to("#svgall", { "stroke-dashoffset": svgposition, duration: 0.5 });
				//ローダー画面内の％表示を書き換え
				console.log(loadrate + "%");
				per.innerHTML = loadrate + "%";
				//読み込み割合が100だったらSVGを100％表示にした後、ローダー画面をゆっくり消す
				if (loadrate === 100) {
					const lordertimeline = gsap.timeline({ delay: 1 });
					lordertimeline.to("#svgall", { "stroke-dashoffset": 0, duration: 0.5 });
					lordertimeline.to(".lorder", { opacity: 0, duration: 2 });
					lordertimeline.to(".lorder", { display: "none", duration: 0.1 });
				}
			});
		}

		//動画なら読み込み後にloadeddataが発火するので、リスナーをセット
		//実行内容は画像の時と同じ
		works.addEventListener("loadeddata", () => {
			loadcount = loadcount + 1;
			loadrate = Math.floor((loadcount / nodelistlength) * 100);
			svgrate = Math.floor(loadcount / nodelistlength);
			svgposition = 2700 - svgposition * svgrate;
			gsap.to("#svgall", { "stroke-dashoffset": svgposition, duration: 0.5 });
			console.log(loadrate + "%");
			per.innerHTML = loadrate + "%";
			if (loadrate === 100) {
				const lordertimeline = gsap.timeline({ delay: 1 });
				lordertimeline.to("#svgall", { "stroke-dashoffset": 0, duration: 0.5 });
				lordertimeline.to(".lorder", { opacity: 0, duration: 2 });
				lordertimeline.to(".lorder", { display: "none", duration: 0.1 });
			}
		});
	});
	window.addEventListener("load", () => {
		const lordertimeline = gsap.timeline({ delay: 1 });
		lordertimeline.to("#svgall", { "stroke-dashoffset": 0, duration: 0.5 });
		lordertimeline.to(".lorder", { opacity: 0, duration: 2 });
		lordertimeline.to(".lorder", { display: "none", duration: 0.1 });
		per.innerHTML = "100%";
	});
	//==========================================================

	//ヘッダーをクリックしたらトップへスクロール
	const headerlogo = document.querySelector("#logo");
	headerlogo.addEventListener("click", () => {
		window.scrollTo(0, 0);
	});

	// 作品をクリックしたらオーバーレイ表示==========================
	//portfolio-wrapクラス要素を全て取得してリストに格納
	const pwlist = document.querySelectorAll(".portfolio-wrap");
	//リストから一つずつ取り出してクリックイベントリスナーを設定
	pwlist.forEach((portfoliowrap) => {
		portfoliowrap.addEventListener("click", () => {
			//portfoliowrapの子要素達を取得HTMLcollectionになる
			const portfoliowrapnode = portfoliowrap.children;
			//HTMLcollection内2番目のアイテムの子要素達を、また取得してHTMLcollectionにする
			const wordwrapnode = portfoliowrapnode.item(1).children;
			//タイトル、サブタイトル、日付、ナンバーをそれぞれ取得
			const sumnailtitle = wordwrapnode.item(0).innerHTML;
			const sumnailsubtitle = wordwrapnode.item(1).innerHTML;
			const sumnaildate = wordwrapnode.item(2).innerHTML;
			const sumnailno = wordwrapnode.item(3).innerHTML;

			//オーバーレイ内のwordwrapを取得、サムネイルからぬきだしたそれぞれを埋め込む
			const overlaywordwrap = document.querySelector(".overlayword-wrap");
			const overlaywordwrapchild = overlaywordwrap.children;
			overlaywordwrapchild.item(0).innerHTML = sumnailtitle;
			overlaywordwrapchild.item(1).innerHTML = sumnailsubtitle;
			overlaywordwrapchild.item(2).innerHTML = sumnaildate;
			overlaywordwrapchild.item(3).innerHTML = sumnailno;

			//オーバーレイを取得
			const overlay = document.querySelector(".over-lay");
			//オーバーレイ内の最初の要素imgとかvideo,canvasとか取得
			const overlaymedia = overlay.firstChild;
			//クリックした作品要素の要素名がimg,video,canvasで動作を切り分ける
			switch (portfoliowrapnode.item(0).nodeName) {
				//VIDEOだったら
				case "VIDEO":
					//クリックした作品の要素をクローン
					const overlayvideo = portfoliowrapnode.item(0).cloneNode(true);
					//オーバーレイ用のクラスを付けて
					overlayvideo.classList.add("overlay-video");
					//オーバーレイ内のimg,video,canvasとクローンをリプレース
					overlaymedia.replaceWith(overlayvideo);

					//muteボタンの表示 hideクラスを外す
					const overlayvideobuttonwrap = document.querySelector(".overlay-video-button-wrap");
					overlayvideobuttonwrap.classList.remove("overlay-video-button-wrap-hide");
					//videoタグ取得
					const video = document.querySelector(".overlay-video");
					//muteボタン取得
					const soundbtn = document.querySelector(".overlay-video-button");
					//mute画像取得
					const soundbtnimg = document.querySelector("#mutesvg");
					//回転アニメーション
					const tl = gsap.timeline({});
					tl.to(soundbtnimg, {
						rotation: 100000,
						duration: 1000,
						repeat: -1,
						//アニメが途中で邪魔されたら停止
						overwrite: true,
					});
					//いきなり回転してしまうから取り合えず一回停止
					tl.pause();
					console.log(video.muted);
					//muteﾎﾞﾀﾝがおされたら実行
					soundbtn.addEventListener("click", () => {
						//もしmutedがtrueなら解除 falseならmute
						if (video.muted) {
							//button要素回転タイムラインをスタート
							tl.play();
							//mute解除
							video.muted = false;
							//音量を0.1に設定
							video.volume = 0.1;
							console.log("mute解除");
							console.log(video.volume);
						} else {
							//button要素回転タイムラインをストップ
							tl.pause();
							//mute
							video.muted = true;
							//音量0に設定
							video.volume = 0;
							console.log("muteしました");
							console.log(video.volume);
						}
					});
					break;

				case "CANVAS":
					console.log(document.querySelector("#sec4 .webgl"));
					const overlaycanvas = portfoliowrapnode.item(0).cloneNode(true);
					overlaycanvas.classList.add("overlay-canvas");
					overlaymedia.replaceWith(overlaycanvas);
					//threeの自作レンダー関数を実行
					ovrender();
					break;

				case "IMG":
					const overlayimg = portfoliowrapnode.item(0).cloneNode(true);
					overlayimg.classList.add("overlay-img");
					overlaymedia.replaceWith(overlayimg);
					break;

				case "svg":
					const overlaysvg = portfoliowrapnode.item(0).cloneNode(true);
					overlaysvg.classList.add("overlay-svg");
					overlaymedia.replaceWith(overlaysvg);

					break;
			}

			//hideクラスを取り除いてオーバーレイを表示
			const overlaywrap = document.querySelector(".overlay-wrap");
			overlaywrap.classList.remove("overlay-wraphide");

			//close画像をクリックしたらオーバーレイにhideクラスを設定
			const overlaycloseimg = document.querySelector(".overlaycloseimg");
			overlaycloseimg.addEventListener("click", () => {
				//トゥイーンを兎に角停止
				gsap.killTweensOf(".overlay-video-sound-img");
				overlaywrap.classList.add("overlay-wraphide");
				//videoタグ取得
				const video = document.querySelector(".overlay-video");
				//mute
				video.muted = true;
				//muteボタンにもhideクラスを設定
				const overlayvideobuttonwrap = document.querySelector(".overlay-video-button-wrap");
				overlayvideobuttonwrap.classList.add("overlay-video-button-wrap-hide");
			});

			console.log(portfoliowrap + "がクリックされました。");
		});
	});

	//自己紹介部分の表示不表示
	const aboutbt = document.querySelector("#about-bt");
	const aboutcont = document.querySelector("#about-cont");
	const closeimg = document.querySelector("#closeimg");
	const piechartsvg = document.querySelector("#piechartsvg");
	//abputボタンがおされたら
	aboutbt.addEventListener("click", () => {
		//aboutオーバーレイのhideクラスを取り除く
		aboutcont.classList.remove("hide");
		//aboutオーバーレイ内のパイチャートsvgのuseタグのstroke-dashoffsetを操作して手書き風に表示できる。
		gsap.to("#piechartsvg", { "stroke-dashoffset": 0, duration: 4 });
	});
	closeimg.addEventListener("click", () => {
		//aboutオーバーレイにhideクラスをつけて非表示
		aboutcont.classList.add("hide");
		//パイチャートsvgのstroke-dashoffsetをリセット。
		gsap.to("#piechartsvg", { "stroke-dashoffset": 900, duration: 0.1 });
	});

	//video再生パート--------------------------------------
	const supportsVideo = !!document.createElement("video").canPlayType;
	if (supportsVideo) {
		// set up custom controls
	}

	//three.js==================================
	const gltfLoader = new GLTFLoader();
	const scene = new THREE.Scene();

	//いろんな所で使い回すサイズ=============
	let w = window.innerWidth;
	let h = window.innerHeight;
	const mbw = window.innerWidth / 2;
	const mbh = window.innerHeight / 1.5;
	const sizes = { width: w, height: h, mobilewidth: mbw, mobileheight: mbh };
	window.addEventListener("resize", () => {
		w = window.innerWidth;
		console.log(w);
		h = window.innerHeight;
		sizes.width = w;
		sizes.height = h;
	});

	//カメラ===============
	const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height);
	camera.position.z = 15;
	camera.position.x = 10;
	camera.position.y = 20;
	//camera.lookAt(gltf.scene.children[1].position)
	scene.add(camera);

	//gltf読み込み============
	let tama, yuka, hako;
	gltfLoader.load(
		"./static/blender-three-test2.gltf",
		(gltf) => {
			/* console.log("読み込み成功！！");
			console.log(gltf);
			console.log(gltf.scene);
			console.log(gltf.scene.children[0]);
			console.log(gltf.scene.children[1]);
			console.log(gltf.scene.children[2]);
			console.log(gltf.scene.children[3]); */
			hako = gltf.scene.children[1];
			tama = gltf.scene.children[2];
			yuka = gltf.scene.children[3];

			camera.lookAt(hako.position);

			//影の設定==================
			gltf.scene.children[0].castShadow = true;
			gltf.scene.children[0].receiveShadow = true;
			gltf.scene.children[1].castShadow = true;
			gltf.scene.children[1].receiveShadow = true;
			gltf.scene.children[2].castShadow = true;
			gltf.scene.children[2].receiveShadow = true;
			//gltf.scene.children[3].castShadow=true
			gltf.scene.children[3].receiveShadow = true;

			scene.add(gltf.scene);

			//ライト=================
			const ambientLight = new THREE.AmbientLight(0xe7a03f, 1);
			scene.add(ambientLight);
			const directionalLight = new THREE.DirectionalLight(0x00fffc, 5);
			directionalLight.position.set(15, 10, 4);
			directionalLight.castShadow = true;
			directionalLight.shadow.mapSize.width = 512;
			directionalLight.shadow.mapSize.height = 512;
			directionalLight.shadow.camera.near = 0.5;
			directionalLight.shadow.camera.far = 1000;
			scene.add(directionalLight);
			const hemisphereLight = new THREE.HemisphereLight(0xff0000, 0x0000ff, 0.3);
			//scene.add(hemisphereLight);
			const pointLight = new THREE.PointLight(0xff9000, 0.5);
			//scene.add(pointLight);
			const rectAreaLight = new THREE.RectAreaLight(0x4e00ff, 5, 1, 1);
			//scene.add(rectAreaLight);
			const spotLight = new THREE.SpotLight(0x78ff00, 0.5, 10, Math.PI * 0.1, 0.25, 1);
			spotLight.position.set(0, 2, 3);
			//scene.add(spotLight);

			//ヘルパーとかgui===================
			const helper = new THREE.CameraHelper(directionalLight.shadow.camera);
			//scene.add( helper );
			//const gui = new dat.GUI()
			//gui.add(mesh.position, 'y')

			//サムネイル用のレンダー=========================
			const canvaslist = document.querySelectorAll(".webgl");
			const renderer = new THREE.WebGLRenderer({ canvas: canvaslist[0] });
			//影機能ON
			renderer.shadowMap.enabled = true;
			renderer.shadowMap.autoUpdate = true;
			renderer.shadowMap.type = THREE.PCFSoftShadowMap;
			//12月27　blenderの色を表現するための設定　LIGのﾌﾞﾛｸﾞより
			renderer.physicallyCorrectLights = true;
			renderer.outputEncoding = THREE.sRGBEncoding;
			renderer.toneMapping = THREE.ACESFilmicToneMapping;
			//複数のキャンパスに描画する為にレンダーを自動でクリアさせない
			renderer.autoClear = false;
			//コントロール==================
			const controls = new OrbitControls(camera, canvaslist[0]);

			renderer.setSize(sizes.mobilewidth, sizes.mobileheight);
			if (window.innerWidth > 840) {
				renderer.setSize(sizes.width / 3, sizes.height * 0.6);
			}
			renderer.render(scene, camera);

			//アニメーション　tick関数を無限ループ======
			const tick = () => {
				tama.rotation.y += 0.003;
				hako.rotation.y -= 0.003;

				//renderer.setSize(sizes.width * 0.17, sizes.height * 0.6);

				//レンダリング
				renderer.render(scene, camera);
				//自分の関数を自分の中で呼び出し無限ループ
				window.requestAnimationFrame(tick);
			};
			tick();
		},
		(error) => {
			//console.log("読み込みエラー");
			//console.log(error);
		}
	);

	//オーバーレイ用にレンダー作って2つ目のcanvasに描画
	function ovrender() {
		//オーバーレイのcanvasを取得
		const ovcanvas = document.querySelector(".overlay-canvas");
		const controls = new OrbitControls(camera, ovcanvas);
		//二つ目のレンダーを作成
		const renderer2 = new THREE.WebGLRenderer({ canvas: ovcanvas });
		const sizes100 = { width: window.innerWidth, height: window.innerHeight };
		renderer2.setSize(sizes100.width, sizes100.height);

		//影機能ON
		//影機能ON
		renderer2.shadowMap.enabled = true;
		renderer2.shadowMap.autoUpdate = true;
		renderer2.shadowMap.type = THREE.PCFSoftShadowMap;
		//12月27　blenderの色を表現するための設定　LIGのﾌﾞﾛｸﾞより
		renderer2.physicallyCorrectLights = true;
		renderer2.outputEncoding = THREE.sRGBEncoding;
		renderer2.toneMapping = THREE.ACESFilmicToneMapping;
		renderer2.autoClear = false;
		renderer2.render(scene, camera);
		function tick() {
			tama.rotation.y += 0.003;
			hako.rotation.y -= 0.003;
			renderer2.render(scene, camera);
			window.requestAnimationFrame(tick);
		}
		tick();
	}
	//即時関数の閉じ
})();
//初回実行
